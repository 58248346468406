import * as S from "./PostappDashboardContainer.styled";

import logoSm from "../../assets/images/logo_sm.webp";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import VerticalAlignCenterIcon from "@mui/icons-material/VerticalAlignCenter";
import ShuffleOutlinedIcon from "@mui/icons-material/ShuffleOutlined";
import PowerSettingsNewSharpIcon from "@mui/icons-material/PowerSettingsNewSharp";
import version from "../../helpers/version";
import getEnv from "../../helpers/env";
import { keycloak } from "context/KeycloakContext";
import { useGroupContext } from "context/GroupContext";
import Settings from "@mui/icons-material/Settings";
import SsidChartOutlinedIcon from "@mui/icons-material/SsidChartOutlined";
import Dashboard from "@mui/icons-material/Dashboard";
import Radar from "@mui/icons-material/Radar";
import { Link } from "@tanstack/react-router";
import { Tooltip } from "@mui/material";
import { forwardRef } from "react";

const LogoutIcon = forwardRef<HTMLDivElement>((props, ref) => {
  if (!keycloak.authenticated) {
    return null;
  }

  return (
    <div {...props} ref={ref}>
      <S.Logout
        onClick={() => {
          keycloak.logout({
            redirectUri: getEnv("REACT_APP_KEYCLOAK_POST_LOGOUT_REDIRECT_URL"),
          });
        }}
      >
        <PowerSettingsNewSharpIcon fontSize="medium" />
      </S.Logout>
    </div>
  );
});

const NavBar = () => {
  const { elementSelected } = useGroupContext();
  const rootGroupName = elementSelected?.metadata?.root?.["name"];

  const oneDimensionalyURL = `${getEnv(
    "REACT_APP_GRAFANA_URL",
  )}/d/one-dimensional-anomaly-detection_oc/1d-anomaly-detection?orgId=1${
    rootGroupName ? `&var-root_group=${rootGroupName}` : ""
  }`;
  const trendsURL = `${getEnv("REACT_APP_GRAFANA_URL")}/d/trends_oc/trends?orgId=1${
    rootGroupName ? `&var-root_group=${rootGroupName}` : ""
  }`;
  const corrURL = `${getEnv("REACT_APP_GRAFANA_URL")}/d/correlations/korelacje?orgId=1${
    rootGroupName ? `&var-root_group=${rootGroupName}` : ""
  }`;

  return (
    <S.NavBar>
      <S.NavBarLogo src={logoSm} alt="logo" />
      <S.NavBarIconsContainer>
        <S.NavBarIconsGroup>
          <Link to="/">
            <Tooltip title="Panel główny">
              <Dashboard />
            </Tooltip>
          </Link>
          <Link to="/models">
            <Tooltip title="Konfiguracja modeli">
              <Settings />
            </Tooltip>
          </Link>
          <Link to="/models_ml">
            <Tooltip title="Modele normalnej pracy">
              <SsidChartOutlinedIcon />
            </Tooltip>
          </Link>
          <Link to="/events">
            <Tooltip title="Konfiguracja zdarzeń">
              <Radar />
            </Tooltip>
          </Link>
        </S.NavBarIconsGroup>
        <S.NavBarIconsGroup>
          <Tooltip title="Detekcja anomalii 1D">
            <a href={oneDimensionalyURL} target="_blank" rel="noreferrer">
              <VerticalAlignCenterIcon />
            </a>
          </Tooltip>
          <Tooltip title="Detekcja trendów">
            <a href={trendsURL} target="_blank" rel="noreferrer">
              <TrendingUpIcon />
            </a>
          </Tooltip>
          <Tooltip title="Analiza korelacji">
            <a href={corrURL} target="_blank" rel="noreferrer">
              <ShuffleOutlinedIcon />
            </a>
          </Tooltip>
        </S.NavBarIconsGroup>
      </S.NavBarIconsContainer>
      <Tooltip title="Wyloguj się">
        <LogoutIcon />
      </Tooltip>
      <S.NavBarVersion>{version}</S.NavBarVersion>
    </S.NavBar>
  );
};

export default NavBar;
